import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetchData from "../hooks/useFetchData";
import fetchBLSTRSData from "../hooks/useFetchBLSTRSData";
import { categorizeData, getSvgMap, getCountyIds } from "../utils/dashboardUtils";
import { handleExcelDownload } from "../utils/excelExportModule";
import MetricsModal from "./MetricsModal";
import LegendMetricModal from "../components/LegendMetricModal";
import demoSvg from '../assets/demo.svg';
import workforceSvg from '../assets/workforce.svg';
import economySvg from '../assets/economy.svg';
import interactionsSvg from '../assets/interactions-tab.svg';
import LoadingIndicator from "../components/LoadingIndicator";
import DemographicMetrics from "../components/panels/DemographicMetrics";
import WorkforceMetrics from "../components/panels/WorkforceMetrics";
import EconomyMetrics from "../components/panels/EconomyMetrics";
import InteractionMetrics from "../components/panels/InteractionMetrics";
import DownloadLoading from "../components/DownloadLoading";

const tabs = ["Demographics", "Workforce", "Economy", "Interactions"];

const tabSvgMap: { [key: string]: string } = {
    "Demographics": demoSvg,
    "Workforce": workforceSvg,
    "Economy": economySvg,
    "Interactions": interactionsSvg,
};

const Dashboard = () => {
    const isIframe = window.self !== window.top;
    const navigate = useNavigate();

    const { data: blsTrsData, loading: BLSLoading }: any = fetchBLSTRSData(getCountyIds());
    const { indicators, interactionData, geoDetails, loading, dashboardView, geoTag } = useFetchData();

    const isDataLoading = BLSLoading || loading;

    // Combine data from all sources
    const allDataAvailable = !isDataLoading && indicators;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [legendUrl, setLegendUrl] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [siteUrl, setSiteUrl] = useState('');
    const [legendId, setLegendId] = useState(0);
    const [isLegendModalOpen, setIsLegendModalOpen] = useState(false);
    const [shouldDownloadExcel, setShouldDownloadExcel] = useState(false);
    const [showDownloadLoading, setShowDownloadLoading] = useState(false);

    // Geographic levels and exclusion lists defined for excel (will vary by project)
    const geographicLevels = ['tract', 'block group'];
    const summaryExclusionList: string[] = [];
    const disaggregationExclusionList: string[] = [];
    const geographyExclusionList: string[] = [];
    

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
          if (event.data.message === 'pdf') {
            setModalUrl(event.data.params.url);
            setLogoUrl(event.data.params.logoUrl || '');
            setLegendUrl(event.data.params.legendUrl || '');
            setSelectedArea(event.data.params.selectedArea || '');
            setSiteUrl(event.data.params.siteUrl || '');
            setIsModalOpen(true);
            
            // Send message to expand
            window.parent.postMessage({ 
                message: "expand",
                mode: "fullscreen" 
            }, '*');
            console.log('expand message sent');
          } else if (event.data.message === 'metric-modal') {

            // Legend metadata info icon event
            console.log('Message received from parent app inside legend metric modal:', event.data);
            setLegendId(event.data.params.id);
            setIsLegendModalOpen(true);
          } else if (event.data.message === 'excel') {
            // Get selected area
            const sArea = event.data?.params?.selectedArea;
            // Show loading modal
            setShowDownloadLoading(true);
            
            if (!loading) {
                // Execute excel download script
                handleExcelDownload(geoDetails, geoTag, tabs, sArea, summaryExclusionList, disaggregationExclusionList, geographyExclusionList, geographicLevels, 'Organization Metrics').finally(() => {
                    setShowDownloadLoading(false);
                });
            } else {
                console.log('Data is still loading, will attempt to download Excel later.');
                setShouldDownloadExcel(true); // Set flag to retry download
            }
        }
        };
    
        window.addEventListener('message', handleMessage);
    
        return () => {
          window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        if (!loading && shouldDownloadExcel) {
            setShowDownloadLoading(true);
            handleExcelDownload(geoDetails, geoTag, tabs, selectedArea, summaryExclusionList, disaggregationExclusionList, geographyExclusionList, geographicLevels, 'Organization Metrics').finally(() => {
                setShouldDownloadExcel(false); 
                setShowDownloadLoading(false);
            });
        }
    }, [loading, shouldDownloadExcel, geoDetails]);

    const closeLegendModal = () => {
        setIsLegendModalOpen(false);
        setLegendId(-1);
    };

    const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        let commerceOrigin = '*';

        e.preventDefault();
        setIsModalOpen(false);
        // Send message to expand
        window.parent.postMessage({ message: "expand" }, commerceOrigin);
        console.log('expand message sent');
    };

    // const handleDownloadNoIframe = () => {
    //     // Show loading modal
    //     setShowDownloadLoading(true);
                
    //     if (!loading) {
    //         // Execute excel download script
    //         handleExcelDownload(geoDetails, geoTag, tabs, selectedArea, summaryExclusionList, disaggregationExclusionList, geographyExclusionList, geographicLevels, 'Base Download').finally(() => {
    //             setShowDownloadLoading(false);
    //         });
    //     }
    // };

    // Convert lowercase dashboardView to title case
    const formattedTab = tabs.find(t => t.toLowerCase() === dashboardView) || tabs[0];
    const activeTab = formattedTab;

    const categorizedData = categorizeData(indicators);

    const renderMetrics = (tab: string) => {

        switch(tab) {
            case "Demographics":
                if (loading) {
                    return <LoadingIndicator />;
                }

                return <DemographicMetrics indicators={indicators} categorizedData={categorizedData} tab={tab} geoDetails={geoDetails} blsTrsData={undefined} interactionData={interactionData}/>

            case "Workforce":
                if (loading) {
                    return <LoadingIndicator />;
                }

                return <WorkforceMetrics indicators={indicators} categorizedData={categorizedData} tab={tab} geoDetails={geoDetails} blsTrsData={undefined} interactionData={interactionData}/>

            case "Economy":
                if (loading) {
                    return <LoadingIndicator />;
                }

                return <EconomyMetrics indicators={indicators} categorizedData={categorizedData} tab={tab} geoDetails={geoDetails} blsTrsData={blsTrsData} interactionData={interactionData}/>

            case "Interactions":
                const isInteractionDataLoaded = (data: any[]) => {
                    return data.length > 0;
                }

                if (!isInteractionDataLoaded(interactionData)) {
                    return <LoadingIndicator />;
                }

                return <InteractionMetrics indicators={indicators} categorizedData={categorizedData} tab={tab} geoDetails={geoDetails} blsTrsData={undefined} interactionData={interactionData}/>

            default:
                return null;
        }
    };

    return (
        <div>
            <div className="flex mr-4 items-center justify-end ">
                {/* <button onClick={() => console.log('hi')} className="transition duration-100 ease-in-out transform hover:scale-105 p-2 rounded">
                    <img src={demoSvg} alt="Print button" className="h-6 w-6"></img>
                </button> */}
            </div>
           {!isIframe && <div className="flex m-4 items-center no-print">
                {tabs.map(tabName => (
                    <button
                        key={tabName}
                        className={`flex flex-col items-center w-32 px-4 py-2 rounded-md text-xs ${activeTab === tabName ? 'bg-color-cai-dashboard text-white' : 'bg-white text-color-cai-dashboard'}`}
                        onClick={() => navigate(`/dashboard/${tabName}`)}
                    >
                        <img
                            src={tabSvgMap[tabName]}
                            alt={`${tabName} icon`}
                            className={`w-10 h-10 ${activeTab === tabName ? 'text-white' : 'text-gray-500'}`}
                            style={{ filter: activeTab === tabName ? 'invert(100%)' : 'none' }} // White for active tab
                        />
                        {tabName}
                    </button>
                ))}
            </div> }
            
            <div className="m-4 no-print">
                {allDataAvailable ? renderMetrics(activeTab) : <div className="loading-container">
                    <div className="loading-spinner"></div>
                </div>} {/* Delay rendering until loading is false */}
            </div>
            <LegendMetricModal
                id={legendId}
                isOpen={isLegendModalOpen}
                onClose={closeLegendModal}
                svg={getSvgMap(legendId)}
            />

            {isModalOpen && <MetricsModal
                isOpen={isModalOpen}
                onClose={closeModal}
                tabs={tabs}
                geoDetails={geoDetails}
                geoTag={geoTag}
                pdfUrl={modalUrl}
                logoUrl={logoUrl}
                legendUrl={legendUrl}
                selectedArea={selectedArea}
                siteUrl={siteUrl}
            />}

            {showDownloadLoading &&
                <DownloadLoading/>
            }
        </div>
    );
}

export default Dashboard;